<template>
  <div>
    <confirm-box :dialog="dialog" @confirmed="deletePatient" @cancel="cancel" />
    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="patients"
      :loading="loading"
      :items-per-page="itemsPerPage"
      @page-count="pageCount"
      :page.sync="page"
      item-key="id"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Patients</v-toolbar-title>
          <v-divider class="mx-3" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <export-btn></export-btn>
          <v-btn
            @click="$router.push('/patient')"
            color="teal accent-4"
            depressed
            dark
            class="mb-1 mr-3"
          >
            <v-icon left dark>mdi-filter-variant</v-icon>View All
          </v-btn>
          <v-btn
            @click="showFilterModal = true"
            color="teal accent-4"
            depressed
            dark
            class="mb-1 mr-3"
          >
            <v-icon left dark>mdi-filter-variant</v-icon>Filter
          </v-btn>
           <v-btn
            @click="$router.push('/patient/create')"
            color="teal accent-4"
            depressed
            dark
            class="mb-1 mr-3"
          >
           <v-icon left dark>mdi-plus-circle-outline</v-icon>New Item
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header="{ props: { headers } }">
        <thead class="header">
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="header--text"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ index, item }">
        <tr>

          <td v-if="item.symptom_screening==0">
            <svg style="color:darkgray;"  xmlns="http://www.w3.org/2000/svg" width="1.5em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M21 19v1H3v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V4a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.29c2.97.88 5 3.61 5 6.71v6zm-7 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2"/>
            </svg>
          </td>
          <td v-else>
            <svg style="color:red;"  xmlns="http://www.w3.org/2000/svg" width="1.5em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M21 19v1H3v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V4a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.29c2.97.88 5 3.61 5 6.71v6zm-7 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2"/>
            </svg>
          </td>
          <td>{{ page > 1 ? (page - 1) * 30 + (index + 1) : index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>
            <span v-if="item.mdrtb_reg_no">
              {{ item.reg_unique_code }}
            </span>
          </td>
          <td>{{ calculateAge(item.dob)}}</td>
          <td v-if="item.sex == 1">Male</td>
          <td v-else>Female</td>

          <td v-if="item.pre_enroll == 1">Yes</td>
          <td v-else>No</td>

          <td v-if="item.mdrtb_monopoly==1">No</td>
          <td v-else-if="item.mdrtb_monopoly==2">Yes</td>
          <td v-else>SG</td>

          <td v-if="item.is_auto_nominated">Yes</td>
          <td v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs"> Treatment Finished</span>
              </template>
              <span>{{ item.finished_date }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.township.name }}</td>
         
          <td>
            <span
              :class="{
                'red accent-3': item.comments.length === 0,
                'green accent-3': item.comments.length != 0,
              }"
              class="px-3 py-1 white--text font-weight-bold"
            >
              {{ item.comments.length }}
            </span>
          </td>
          <td v-if="item.remark">{{ item.remark }}</td>
          <td v-else>--</td>
          <td>
            <div class="mr-2">
              <v-btn
                class="white--text"
                depressed
                icon
                :to="`/enroll/clu/${item.id}`"
                v-if="item.diff_finished_change >= 30"
                ><v-icon color="red darken-2"> mdi-message-text </v-icon></v-btn
              >
              <v-btn
                @click="goUpdateForm(item)"
                icon
                color="orange darken-1"
                class="mr-2 white--text"
                depressed
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
              <!-- <v-btn
                @click="openConfrimBox(item)"
                icon
                color="red lighten-1"
                class="white--text"
                depressed
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn> -->
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center py-3">
      <v-pagination
        color="purple accent-4"
        :length="pageCount"
        v-model="page"
        :total-visible="7"
      ></v-pagination>
    </div>
    <filter-box
      v-bind:dialog="showFilterModal"
      @cancel="showFilterModal = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBox from "@/components/notification/ConfirmBox";
import FilterBox from "./components/FilterBox.vue";
import ExportBtn from "./components/ExportBtn.vue";
import { CalculateAge } from "../../mixins/CalculateAge";
export default {
  props: {
    patients: {
      type: Array,
    },
  },
  mixins: [CalculateAge],

  components: {
    ConfirmBox,
    FilterBox,
    ExportBtn,
  },
  created() {
    if (!this.$route.query.page) {
      this.page = 1;
    } else {
      this.page = parseInt(this.$route.query.page);
    }
  },
  data: () => ({
    showFilterModal: false,
    page: 0,
    itemsPerPage: 30,
    item: {},
    dialog: false,
    headers: [
      { text: "App Refer", value: "symptom_screening" },
      { text: "No", value: "no", width: "300px", fixed: true },
      { text: "Name", value: "name", width: "300px" },
      { text: "Code", value: "code", width: "300px" },
      { text: "Age", value: "age", width: "25%" },
      { text: "Sex", value: "sex", width: "25%" },
      { text: "Pre Enroll", value: "pre_enroll" },
      { text: "Mono-Poly/SG", value: "monopoly" },
      { text: "Nominated", value: "Nominated" },
      { text: "Township", value: "township" },
      
      { text: "Feedback", value: "feedback" },
      { text: "Remark", value: "support_remark" },

      { text: "Actions", value: "actions" },
    ],
  }),
  computed: {
    ...mapState("Loading", ["loading"]),
    ...mapState("Patient", ["pageCount"]),
    ...mapState('Admin',['admin']),

    hasHighSideEffectThree() {
      return this.item.dots_patient.some(patient => 
        patient.dots_visit_date.some(visit => 
          visit.high_side_effect.split(',').includes('3')
        )
      );
    }
  },
  watch: {
    page(newValue) {
      this.$router.push("/patient?page=" + newValue).catch(()=>{});
    },
  },
  methods: {
    openConfrimBox(item) {
      this.item = item;
      this.dialog = !this.dialog;
    },
    deletePatient() {
      let data = {
        id: this.item.id,
      };
      this.$store
        .dispatch("Patient/deletePatient", data)
        .then(() => (this.dialog = !this.dialog));
    },
    cancel() {
      this.dialog = !this.dialog;
    },
    goUpdateForm(item) {
      let convertNumber = parseInt(item.id);
      this.$router.push({
        name: "patient-edit",
        params: {
          id: convertNumber,
        },
      });
    },
  },
};
</script>

<style scoped></style>