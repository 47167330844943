<template>
  <export-excel
    type="csv"
    class="export_btn"
    :footer="`Township : ${admin.township.name}`"
    :name="`Patient List (${admin.township.name}).xls`"
    :fetch="getData"
  >
    <v-icon color="white" left>mdi-chevron-right-circle-outline</v-icon> Export
  </export-excel>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  methods: {
    getData() {
      return this.$store.dispatch(
        "Patient/getReportedPatient",
        this.$route.query
      );
    },
  },
  computed: {
    ...mapState("Admin", ["admin"]),
    ...mapGetters("Patient", ["exportedPatientHandler"]),
  },
};
</script>
<style>
.export_btn {
  background: #00bfa5 !important;
  width: 100px;
  height: 35px;
  line-height: 35px;
  margin-bottom: 5px;
  margin-right: 10px;
  text-align: center;
  color: white !important;
}
</style>
