<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="750">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between headline purple accent-4 py-1"
        >
          <div class="subtitle-1 white--text">Patient Filter</div>
          <div>
            <v-btn @click="cancel" class="white--text" icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col lg="8">
              <label>Search</label>
              <v-text-field
                type="text"
                v-model="search"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                class="mt-6 ml-2"
                @click="searchByText"
                color="teal accent-4"
                depressed
                dark
              >
                <v-icon left>mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="4">
              <label>MDRTB Reg No</label>
              <v-text-field
                type="number"
                v-model="mdrtb_reg_no"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col lg="4">
              <label>MDRTB Reg Year</label>
              <v-text-field
                type="number"
                v-model="mdrtb_reg_year"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                class="mt-6 ml-2"
                @click="searchByCode"
                color="teal accent-4"
                depressed
                dark
              >
                <v-icon left>mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="3">
              <label>Enroll Type</label>
              <v-combobox
                v-model="support_type"
                placeholder="choose type"
                outlined
                dense
                item-text="name"
                item-value="id"
                :items="supportTypes"
              ></v-combobox>
            </v-col>
            <v-col lg="3">
              <label>Start Date</label>
              <v-menu
                v-model="supportStartDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    placeholder="choose date"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="supportStartDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="supportStartDate"
                  @input="supportStartDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="3">
              <label>Enddate</label>
              <v-menu
                v-model="supportEndDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    placeholder="choose date"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="supportEndDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="supportEndDate"
                  @input="supportEndDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col lg="3">
              <v-btn
                class="mt-6 ml-2"
                @click="searchBySupportType"
                color="teal accent-4"
                depressed
                dark
              >
                <v-icon left>mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="3">
              <label>Nominated Month</label>
              <v-select
                :items="months"
                v-model="nominated_month"
                item-text="text"
                item-value="value"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col lg="3">
              <label>Nominated Year</label>
              <v-select
                :items="years"
                v-model="nominated_year"
                item-text="text"
                item-value="value"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col lg="3">
              <v-btn
                class="mt-6 ml-2"
                @click="searchByNomated"
                color="teal accent-4"
                depressed
                dark
              >
                <v-icon left>mdi-magnify</v-icon>Search
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  data: () => ({
    search: "",
    supportStartDateMenu: false,
    supportStartDate: "",
    supportEndDateMenu: false,
    supportEndDate: "",
    supportTypes: [
      { id: 1, name: "Pre-Enroll" },
      { id: 2, name: "Enroll" },
    ],
    support_type: "",
    mdrtb_reg_no: "",
    mdrtb_reg_year: "",
    nominated_month: "",
    nominated_year: "",
    nominated_type: "",
    onGoingEndDate: "",
    onGoingEndDateMenu: "",
  }),
  computed: {
    ...mapState("InitialData", ["months"]),
    ...mapState("InitialData", ["years"]),
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    searchByText() {
      this.$router.push(`/patient?search=${this.search}`);
      this.$emit("cancel");
      this.search = null;
    },
    searchBySupportType() {
      if (this.support_type && this.supportStartDate && this.supportEndDate) {
        this.$router.push(
          `/patient?support_type=${this.support_type.id}&support_startdate=${this.supportStartDate}&support_enddate=${this.supportEndDate}`
        );
        this.$emit("cancel");
        this.support_type = null;
        this.supportStartDate = null;
        this.supportEndDate = null;
      }
    },
    searchByCode() {
      if (this.mdrtb_reg_no && this.mdrtb_reg_year) {
        this.$router.push(
          `/patient?mdrtb_reg_no=${this.mdrtb_reg_no}&mdrtb_reg_year=${this.mdrtb_reg_year}`
        );
        this.$emit("cancel");
        this.mdrtb_reg_no = null;
        this.mdrtb_reg_year = null;
      }
    },
    searchByNomated() {
      if (this.nominated_month && this.nominated_year) {
        this.$router.push(
          `/patient?nominated_month=${this.nominated_month}&nominated_year=${this.nominated_year}`
        );
        this.$emit("cancel");
      }
    },
    searchForOnging() {
      if (this.onGoingEndDate) {
        this.$router.push(`/patient?onGoingEnddate=${this.onGoingEndDate}`);
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style scoped>
.detail p {
  font-size: 15px;
  margin-left: 10px;
  letter-spacing: 1px;
}
</style>
