<template>
  <div>
    <v-container fluid>
      <v-card class="card--shadow">
        <patient-list :patients="patients" />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import PatientList from "@/components/patient/PatientList";

import { mapState } from "vuex";

export default {
  components: {
    PatientList,
  },
  computed: {
    ...mapState("Patient", ["patients"]),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.$route.query.search) {
        this.$store.dispatch("Patient/searchPatientsByName", {
          search: this.$route.query.search,
        });
      } else if (
        this.$route.query.support_type &&
        this.$route.query.support_startdate &&
        this.$route.query.support_enddate
      ) {
        this.$store.dispatch("Patient/searchPatientBySupport", {
          support_type: this.$route.query.support_type,
          support_startdate: this.$route.query.support_startdate,
          support_enddate: this.$route.query.support_enddate,
        });
      } else if (
        this.$route.query.mdrtb_reg_no &&
        this.$route.query.mdrtb_reg_year
      ) {
        this.$store.dispatch("Patient/searchPatientsByCode", {
          mdrtb_reg_no: this.$route.query.mdrtb_reg_no,
          mdrtb_reg_year: this.$route.query.mdrtb_reg_year,
        });
      } else if (
        this.$route.query.nominated_month &&
        this.$route.query.nominated_year
      ) {
        this.$store.dispatch("Patient/searchPatientsByNominated", {
          nominated_month: this.$route.query.nominated_month,
          nominated_year: this.$route.query.nominated_year,
        });
      } else if (this.$route.query.onGoingEnddate) {
        this.$store.dispatch("Patient/searchPatientByOnging", {
          onGoingEndDate: this.$route.query.onGoingEnddate,
        });
      } else {
        this.$store.dispatch("Patient/getPatients", {
          page: this.$route.query.page || 1,
        });
      }
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.card--shadow {
  box-shadow: var(--primary-box-shadow) !important;
}
</style>
